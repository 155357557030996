export default {
  "page-wrapper": "qrx",
  "content-wrapper": "qrw",
  "content": "qrN",
  "period-field": "qrv",
  "format-options": "qro",
  "format-option": "qrn",
  "format-radio": "qri",
  "format-description": "qrA body-2"
};
