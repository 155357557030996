export default {
  "container": "qrG",
  "form-container": "qrr",
  "preview-container": "qrb",
  "pdf-preview-container": "qry",
  "content": "qrU",
  "title": "qrj",
  "tabs-container": "qrW",
  "close-button": "qru btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qbS",
  "header": "qbc",
  "without-tabs": "qbq"
};
