export default {
  "vat-number": "qjL body-2",
  "banner": "qjT",
  "container": "qjz",
  "organization-profile": "qja",
  "input": "qjH",
  "medium-input": "qjO medium",
  "small-input": "qjm small",
  "actions": "qjp",
  "company-info": "qjx",
  "company-info-section": "qjw",
  "company-address-section": "qjN",
  "company-country": "qjv",
  "company-billing-email": "qjo",
  "company-address": "qjn"
};
