export default {
  "container": "qyY",
  "wrapper": "qyh",
  "grey-header-block": "qyf",
  "connect-app-header": "qyK",
  "connect-app-header-link": "qyG",
  "connect-app-header-content": "qyr",
  "connect-app-header-content-default": "qyb",
  "connect-app-header-content-left": "qyy",
  "button-block": "qyU",
  "connect-app-body": "qyj",
  "connect-app-body-left-content": "qyW",
  "connect-app-body-sidebar": "qyu"
};
