export default {
  "page-container": "qYa",
  "form": "qYH",
  "close-button": "qYO btn btn--icon-only btn--tertiary btn--large",
  "preview": "qYm",
  "preview-padding": "qYp",
  "title": "qYx",
  "header": "qYw",
  "without-tabs": "qYN",
  "form-section": "qYv",
  "form-footer": "qYo",
  "disclaimer-text": "qYn"
};
