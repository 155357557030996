export default {
  "splash-landing": "qNz",
  "container": "qNa",
  "options": "qNH",
  "physical": "qNO",
  "card-label": "qNm",
  "physical-cta-container": "qNp",
  "physical-cta": "qNx",
  "coming-soon": "qNw"
};
