import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierIndexController extends Controller {
  queryParams = ['page', 'perPage', 'sortBy', 'status', 'search'];

  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = 'supplier:asc';
  @tracked status = 'unarchived';
  @tracked highlighted = null;
  @tracked search = '';

  get suppliers() {
    return this.model.suppliersTask.lastSuccessful?.value || [];
  }

  get hasNoResult() {
    return (
      this.search && !this.suppliers.length && !this.isError && !this.model.suppliersTask.isRunning
    );
  }

  get isError() {
    return this.model.suppliersTask.last.isError;
  }

  get hasError() {
    return this.model.suppliersTask.last?.isError;
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  retryFetchData() {
    let params = {
      status: this.status,
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
    };

    this.model.suppliersTask.perform(params).catch(ignoreCancelation);
  }

  @action
  changePage(page) {
    this.page = page;
  }

  @action
  changePerPage(perPage) {
    this.page = 1;
    this.perPage = perPage;
  }

  @action
  handleShowItem(supplier) {
    this.model.fetchRelatedInvoices(supplier.id);
    this.highlighted = supplier.id;
  }

  @action
  closeSidepanel() {
    this.highlighted = null;
  }

  searchSuppliers(query, resolve) {
    this.search = query;

    resolve(query);
  }

  @action handleSupplierSearch(query) {
    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }
}
