export default {
  "page-wrapper": "quz",
  "content-wrapper": "qua",
  "content": "quH",
  "period-field": "quO",
  "format-options": "qum",
  "format-option": "qup",
  "format-radio": "qux",
  "format-description": "quw body-2"
};
