import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InsuranceHubPoliciesRoute extends Route {
  @service router;
  @service store;
  @service organizationManager;
  @service sentry;

  beforeModel() {
    if (!variation('feature--boolean-insurance-hub-v1')) {
      this.router.replaceWith('insurance-hub');
    }
  }

  model() {
    this.fetchInsuranceContractsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchInsuranceContractsTask: this.fetchInsuranceContractsTask,
    };
  }

  fetchInsuranceContractsTask = dropTask(async () => {
    return await this.store.query('insurance-contract', {
      organization_id: this.organizationManager.organization.id,
    });
  });
}
