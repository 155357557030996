import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import {
  DEFAULT_SOURCE_CURRENCY_CODE,
  DEFAULT_TARGET_CURRENCY_CODE,
} from 'qonto/constants/international-out/quote';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class InternationalOutFlowDataContext {
  transfer = null;
  quote = null;
  fees = null;
  beneficiary = null;
  targetAccount = null;
  bankAccount = null;
  transferDetails = null;
  attachments = [];
  additionalRequirements = null;
  settings = null;

  constructor({ bankAccount, settings }) {
    this.bankAccount = bankAccount;
    this.settings = settings;
  }
}

export default class InternationalOutFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service internationalOutManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;

  constructor() {
    super(...arguments);
    let { defaultAccount, transferSettings } = this.organizationManager.organization;
    let { targetCurrency } = this.router.currentRoute.queryParams;
    this.dataContext = new InternationalOutFlowDataContext({
      bankAccount: defaultAccount,
      settings: {
        maximumAmountWithoutAttachment:
          (transferSettings?.max_amount_without_attachment_cents ?? 0) / 100,
        preferred: {
          sourceCurrency: DEFAULT_SOURCE_CURRENCY_CODE,
          targetCurrency: targetCurrency ?? DEFAULT_TARGET_CURRENCY_CODE,
        },
      },
    });
    this.internationalOutManager.addIdempotencyKey();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let canAccess =
      this.abilities.can('create transfer') && (await this.internationalOutManager.isEligible());

    if (!canAccess) {
      this.homePage.replaceWithDefaultPage();
    }
  }

  @action
  onComplete() {
    this.router.transitionTo('transfers.landing');
  }

  onAbortTask = dropTask(async (_, { id: currentStepId }) => {
    if (currentStepId !== 'quote') {
      let result = await this.openAbortModalTask.perform();

      if (result !== 'confirm') {
        return false;
      }
    }

    this.router.transitionTo('transfers.landing');

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.exit-flow.title'),
      description: this.intl.t('transfers.exit-flow.subtitle'),
      cancel: this.intl.t('transfers.exit-flow.cancel'),
      confirm: this.intl.t('transfers.exit-flow.confirm'),
    });
  });
}
