export default {
  "container": "qWQ",
  "security-method": "qWe",
  "icon": "qWB",
  "body": "qWE",
  "status": "qWd body-2",
  "device": "qWI",
  "device-icon": "qWt",
  "subtitle": "qWM body-2",
  "current": "qWP"
};
