import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import {
  getEmptyStateConfig,
  TAB_NAMES,
} from 'qonto/constants/empty-states/direct-debit-collections';
import { TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';
import { REQUEST_EVENT_METHOD, REQUEST_ORIGINS, STATUS } from 'qonto/constants/requests';
import { EligibilityLossBanner } from 'qonto/react/components/direct-debit-collections/eligibility-loss-banner';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;
const DEFAULT_SORT_BY = 'created_at:desc';

export default class DirectDebitCollectionsRequestsController extends Controller {
  @service('intl') intl;
  @service notifierCounterManager;
  @service organizationManager;
  @service sensitiveActions;
  @service requestsManager;
  @service modals;
  @service router;
  @service segment;
  @service emptyStates;
  @service directDebitCollectionsManager;

  @tracked page = DEFAULT_PAGE;
  @tracked perPage = DEFAULT_PER_PAGE;
  @tracked sortBy = DEFAULT_SORT_BY;

  EligibilityLossBanner = EligibilityLossBanner;

  get multiRequests() {
    return this.model.fetchMultiRequestsTask.lastSuccessful?.value ?? [];
  }

  get pendingMultiRequests() {
    return this.multiRequests.filter(request => request.status === STATUS.PENDING);
  }

  get hasPendingRequests() {
    return this.notifierCounterManager.counter?.directDebitCollectionRequests > 0;
  }

  get availableBankAccounts() {
    return this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;
  }

  get isOrganizationFrenchOrGerman() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' ||
      this.organizationManager.organization.legalCountry === 'DE'
    );
  }

  get emptyStateRevampOptions() {
    let { isEligible } = this.model;

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: isEligible,
      isEmptyGlobally: false,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, {}),
      tab: TAB_NAMES.TASKS,
    });
  }

  @action
  onBankAccountSelect(multiRequest, bankAccount) {
    multiRequest.bankAccount = bankAccount;

    this.#track('request_account_selected', multiRequest);
  }

  @action
  onRequestClick(multiRequest) {
    this.router.transitionTo('direct-debit-collections.requests.request', multiRequest.id);

    this.#track('request_details_opened', multiRequest);
  }

  @action
  onRequestKeyboardSelect(multiRequest, event) {
    if (event.key !== 'Enter' && event.key !== ' ') {
      return;
    }

    this.router.transitionTo('direct-debit-collections.requests.request', multiRequest.id);

    this.#track('request_details_opened', multiRequest);
  }

  @action
  handleSortBy(sortBy) {
    this.page = DEFAULT_PAGE;
    this.sortBy = sortBy;
  }

  @action
  handlePerPageChange(perPage) {
    this.page = DEFAULT_PAGE;
    this.perPage = perPage;
  }

  onRequestApproveTask = dropTask(async multiRequest => {
    this.#track('request_approved_clicked', multiRequest);

    await this.sensitiveActions.runTaskNoDrop.perform(
      this.requestsManager.approveMultiRequestDirectDebitCollectionTask,
      multiRequest
    );
  });

  onRequestRejectTask = dropTask(async multiRequest => {
    this.#track('request_declined_clicked', multiRequest);

    await this.modals.open('request/sidebar/modals/decline-request', {
      request: multiRequest,
      confirmTask: this.confirmRejectionTask,
    });
  });

  confirmRejectionTask = dropTask(async (closeRejectModal, multiRequest) => {
    await this.requestsManager.rejectMultiRequestDirectDebitCollectionTask.perform(multiRequest);

    closeRejectModal();
  });

  #track(eventName, { id, totalCount, requestType, status }) {
    let trackingParams = {
      request_id: id,
      request_type: requestType,
      direct_debits_count: totalCount,
      origin: REQUEST_ORIGINS.INCOMING_DIRECT_DEBIT,
      role: this.organizationManager.membership.role,
    };

    switch (eventName) {
      case 'request_details_opened':
        trackingParams = {
          ...trackingParams,
          request_status: status,
        };
        break;
      case 'request_approved_clicked':
      case 'request_declined_clicked':
      case 'request_account_selected':
        trackingParams = {
          ...trackingParams,
          method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
        };
        break;
    }

    this.segment.track(eventName, trackingParams);
  }

  @action
  setupDirectDebitSubscription() {
    if (this.isEmptyState && this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, TRACKING_ORIGINS.HEADER);
    }

    this.directDebitCollectionsManager.handleRedirectionOnSetupCollection({
      redirectRoute: 'direct-debit-collections.requests.index',
    });
  }
}
