export default {
  "container": "qnp",
  "page-wrapper": "qnx",
  "header": "qnw",
  "back-button-wrapper": "qnN",
  "header-inner": "qnv",
  "header-main-wrapper": "qno",
  "header-main": "qnn",
  "header-right": "qni",
  "content-wrapper": "qnA",
  "content": "qnY",
  "content-body": "qnh",
  "sidebar": "qnf"
};
