export default {
  "dropdown-icon": "ZSg",
  "dropdown": "ZSD",
  "download-button": "ZSV",
  "connections-banner": "ZSL",
  "recommendation-cards": "ZST",
  "wrapper-with-cards": "ZSz",
  "transactions-wrapper": "ZSa",
  "separator": "ZSH",
  "with-transaction": "ZSO",
  "annual-billing-banner": "ZSm",
  "mt-132": "ZSp",
  "transactions-sidebar": "ZSx",
  "empty-state-wrapper": "ZSw"
};
