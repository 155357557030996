export default {
  "signin": "quI",
  "form": "qut",
  "illustration": "quM",
  "purple": "quP",
  "mint": "qul",
  "mustard": "quX",
  "peach": "quC",
  "form-wrapper": "quk",
  "header-logo": "quJ",
  "links": "qus",
  "no-account-label": "quF",
  "spinner-container": "qug",
  "main-image-container": "quD",
  "main-image": "quV",
  "wise-logo-container": "quL",
  "wise-logo": "quT"
};
