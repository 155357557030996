export const accountReceivableOnboarding = {
  steps: {
    summary: {
      componentName: 'account-receivable-onboarding/summary',
      nextStepId: ({ nextStepId }) => nextStepId,
    },
    numbering: {
      componentName: 'account-receivable-onboarding/numbering',
      nextStepId: 'summary',
    },
    'company-details': {
      componentName: 'account-receivable-onboarding/company-details',
      nextStepId: ({ isFrenchOrganization }) =>
        isFrenchOrganization ? 'fr-terms-and-conditions' : 'summary',
    },
    'fr-terms-and-conditions': {
      componentName: 'account-receivable-onboarding/fr-terms-and-conditions',
      nextStepId: 'summary',
    },
    'it-company-details': {
      componentName: 'account-receivable-onboarding/it-company-details',
      nextStepId: 'summary',
    },
    'customization-logo': {
      componentName: 'account-receivable-onboarding/customization-logo',
      nextStepId: 'customization-brand-color',
    },
    'customization-brand-color': {
      componentName: 'account-receivable-onboarding/customization-brand-color',
      nextStepId: ({ isGermanOrganization }) =>
        isGermanOrganization ? 'de-customization-custom-messages' : 'summary',
    },
    'de-customization-custom-messages': {
      componentName: 'account-receivable-onboarding/de-customization-custom-messages',
      nextStepId: 'summary',
    },
    success: {
      componentName: 'success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
