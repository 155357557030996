import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class AccountReceivableOnboardingFlowDataContext {
  nextStepId = null;
  onboardingState = null;
  settings = null;

  legalCountry = null;
  isFrenchOrganization = null;
  isItalianOrganization = null;
  isGermanOrganization = null;

  constructor({ legalCountry }) {
    this.legalCountry = legalCountry;

    this.isFrenchOrganization = legalCountry === 'FR';
    this.isItalianOrganization = legalCountry === 'IT';
    this.isGermanOrganization = legalCountry === 'DE';
  }
}

export default class AccountReceivableOnboardingFlowSetup extends FlowSetup {
  @service router;
  @service homePage;
  @service organizationManager;
  @service abilities;
  @service store;
  @service toastFlashMessages;
  @service intl;

  constructor() {
    super(...arguments);

    this.dataContext = new AccountReceivableOnboardingFlowDataContext({
      legalCountry: this.organizationManager.organization.legalCountry,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    if (this.abilities.cannot('complete accountsReceivableOnboarding')) {
      return this.homePage.visitDefaultPage();
    }

    try {
      let organizationId = this.organizationManager.organization.id;
      this.dataContext.onboardingState = await this.store.findRecord(
        'accounts-receivable-onboarding',
        organizationId
      );
      this.dataContext.settings = await this.store.findRecord(
        'receivable-invoices-settings',
        organizationId
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.homePage.visitDefaultPage();
    }
  }

  onComplete() {
    // This function is executed when the user, in the last step of the flow, clicks a CTA that fires the transitionToNext function.
    // Normally this function is used to redirect the user to a specific route.
  }

  onAbort() {
    // This function is executed when the user clicks the X button to close the FiF.
    // Here you can ask the user for a confirmation with a modal
  }
}
