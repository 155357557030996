export default {
  "team": "qfa",
  "no-members": "qfH",
  "teams-header": "qfO",
  "header-search": "qfm",
  "search-bar": "qfp",
  "invite": "qfx body-2",
  "invite-text": "qfw",
  "empty-illustration": "qfN",
  "empty-title": "qfv title-3",
  "empty-description": "qfo body-2",
  "pagination-footer": "qfn",
  "filter-search": "qfi",
  "fullsize": "qfA",
  "tabs-container": "qfY",
  "tabs-separator": "qfh"
};
