export default {
  "wrapper": "qjt",
  "integrations": "qjM",
  "description": "qjP body-2",
  "api": "qjl flex flex-column",
  "api-container": "qjX flex large",
  "api-input": "qjC large",
  "api-btn": "qjk",
  "key-input": "qjJ"
};
