export default {
  "main-container": "qNh",
  "main": "qNf",
  "main-wrapper": "qNK",
  "close-button": "qNG",
  "active-block": "qNr",
  "title": "qNb",
  "list-options": "qNy",
  "card": "qNU",
  "card-container": "qNj",
  "card-image": "qNW",
  "card-tiles": "qNu"
};
