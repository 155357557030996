export default {
  "container": "qrd",
  "form-container": "qrI",
  "preview-container": "qrt",
  "pdf-preview-container": "qrM",
  "content": "qrP",
  "title": "qrl",
  "tabs-container": "qrX",
  "close-button": "qrC btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrk",
  "header": "qrJ",
  "without-tabs": "qrs"
};
