import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ParsingError, PrismicError } from '@prismicio/client';
import { variation } from 'ember-launch-darkly';

export default class InsuranceHubOfferRoute extends Route {
  @service prismic;
  @service router;
  @service store;

  model({ uid }) {
    return this.getInsuranceOffer(uid);
  }

  @action
  error(error) {
    if (error.message === 'not-found') {
      this.router.replaceWith('/404');
    } else {
      return true;
    }
  }

  async getInsuranceOffer(uid) {
    let insuranceOffer;

    try {
      insuranceOffer = await this.prismic.getByUID('insurance_offer', uid, {
        fetchLinks: ['insurance_risk.name', 'insurance_provider.logo'],
      });

      if (variation('feature--boolean-insurance-hub-v1')) {
        let insuranceContracts = await this.store.findAll('insurance-contract');
        let hasActiveContract = insuranceContracts.find(
          contract => contract.type === insuranceOffer.data.type
        );
        return { uid, ...insuranceOffer.data, hasActiveContract };
      }
      return { uid, ...insuranceOffer.data };
    } catch (error) {
      if (
        error instanceof ParsingError ||
        error.message === 'An invalid API response was returned'
      ) {
        throw new Error('bad-request');
      }

      if (error instanceof PrismicError) {
        throw new Error('not-found');
      }

      throw error;
    }
  }
}
