export default {
  "container": "qKc",
  "content": "qKq",
  "logos": "qKZ",
  "logo": "qKR",
  "animation": "qKQ",
  "title": "qKe title-2 mb-32",
  "avatar": "qKB",
  "actions": "qKE mb-32",
  "footer": "qKd body-2"
};
