import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ORGA_STATUS } from 'qonto/constants/organization';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class CompanyProfileIndexController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service router;
  @service store;
  @service banners;
  @service bannerFlashMessages;
  @service segment;
  @service sentry;
  @service organizationManager;

  @tracked hasMissingInfoError = false;

  constructor() {
    super(...arguments);

    scrollIntoView('#company-profile-header');
  }

  @action
  onDeactivationCanceled() {
    this.router.refresh('settings.company-profile.index');
  }

  @action
  onDocumentCollectionUpload() {
    this.segment.track('org_details_upload_documents_clicked');
    this.router.transitionTo('upload-periodic-update-documents');
  }

  get isAccountClosingBannerVisible() {
    return (
      this.model.organization.status !== ORGA_STATUS.SUSPENDED &&
      this.model.deactivationData?.ongoing
    );
  }

  get isReadOnly() {
    let { organization, updateProcess } = this.model;

    if ([ORGA_STATUS.DEACTIVATED, ORGA_STATUS.SUSPENDED].includes(organization.status)) {
      return true;
    }

    return !organization.kybAccepted || updateProcess.inReview;
  }

  get documentCollectionProcess() {
    let { documentCollectionProcesses } = this.model;

    if (!Array.isArray(documentCollectionProcesses)) {
      return undefined;
    }

    return documentCollectionProcesses.find(
      dcp => dcp.reason === 'kyc_kyb_periodic_update_process'
    );
  }

  get isDocumentCollectionBannerVisible() {
    let { updateProcess } = this.model;
    let { status } = updateProcess;
    let documentStatus = this.documentCollectionProcess?.status;

    if (!documentStatus) {
      return false;
    }

    // KYB/C might start in `created` status, but after the `.save()` status is changed to `pending_review`.
    // So while we wait for the `.save()` to finish, we should show the same initial banner.
    let initialState =
      (status === 'created' || status === 'pending_review') && documentStatus === 'created';

    return (
      documentStatus === 'pending_upload' ||
      (status === 'pending_review' && documentStatus === 'pending_review') ||
      initialState
    );
  }

  get isInReviewBannerVisible() {
    // There are two scenarios where the update process is in review but the document
    // collection process is still ongoing. In this case, we should not show
    // the in-review banner. We will show the document collection banner instead.
    // This will avoid showing two banners at the same time.
    if (this.isDocumentCollectionBannerVisible) {
      return false;
    }

    let { updateProcess } = this.model;
    let { inReview, isSaving } = updateProcess;

    return inReview && !isSaving;
  }

  get isSubmitBannerVisible() {
    let { updateProcess } = this.model;
    let {
      isCompanyReportStatusNotFound,
      isCompanyReportStatusNull,
      isSaving,
      hasChanges,
      isEditable,
      legallyRequired,
    } = updateProcess;

    if (isSaving) {
      return true;
    }

    let kycbRequired =
      legallyRequired && (isCompanyReportStatusNotFound || isCompanyReportStatusNull);

    return isEditable && (kycbRequired || hasChanges);
  }

  get filteredRepresentativeChangeRequests() {
    return this.model.representativeChangeRequests.filter(
      ({ corporateOfficer, ubo }) => corporateOfficer || ubo
    );
  }

  get isProofOfRegistrationUploaded() {
    return Boolean(this.model.kbis);
  }

  get shouldDisplaySubmitKycUpsizeBanner() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }

  submitTask = dropTask(async () => {
    this.hasMissingInfoError =
      this.model.organizationChangeRequest.hasMissingInformation ||
      this.model.ownerChangeRequest.hasMissingInformation ||
      this.filteredRepresentativeChangeRequests.some(it => it.hasMissingInformation);

    if (this.hasMissingInfoError) return;

    let { updateProcess } = this.model;

    if (!updateProcess.legallyRequired && updateProcess.needsReview) {
      // if the update process needs a CS review we show a confirmation dialog, before
      // we submit the changes (only if the update process is not legally required).

      let confirmationTexts = {
        title: this.intl.t('organizations.profile.company-profile.modal-confirm.title'),
        subtitle: this.intl.t('organizations.profile.company-profile.modal-confirm.subtitle'),
        disclaimer: this.intl.t('organizations.profile.company-profile.modal-confirm.disclaimer'),
      };

      let result = await this.modals.open('organization-profile/confirmation-dialog', {
        updateProcess,
        confirmationTexts,
      });

      // if the user cancels the submission we return early before saving
      // anything to the server.
      if (result !== 'confirm') return;
    }

    try {
      updateProcess.status = 'pending_review';

      await updateProcess.save();

      this.segment.track('kycb_update_confirmed');

      if (updateProcess.inReview) {
        let message = this.intl.t('organizations.profile.company-profile.toast-sent-for-review');
        this.toastFlashMessages.toastInfo(message);
      } else if (!updateProcess.inReview) {
        if (
          updateProcess.status === 'accepted' &&
          updateProcess.legallyRequired === true &&
          !this.model.organizationChangeRequest.differsFromOriginal &&
          !this.model.ownerChangeRequest.differsFromOriginal &&
          !this.model.representativeChangeRequests.some(cr => cr.differsFromOriginal)
        ) {
          let message = this.intl.t(
            'organizations.profile.company-profile.toast-edit-no-review-success'
          );
          this.toastFlashMessages.toastSuccess(message);
        } else {
          let message = this.intl.t('organizations.profile.company-profile.toast-edit-success');
          this.toastFlashMessages.toastSuccess(message);
        }
      }

      this.bannerFlashMessages.clearTopBannerMessages();
      this.banners.triggerBanners();
    } catch {
      let message = this.intl.t('organizations.profile.company-profile.generic-error');
      this.toastFlashMessages.toastError(message);
    }

    if (!updateProcess.inReview) {
      // if the changes have been applied without review we need to reload
      // everything to ensure that we have up-to-date data.
      this.router.refresh('protected');
    }
  });

  submitDocumentCollectionTask = dropTask(async () => {
    this.segment.track('org_details_kycb_update_banner_clicked');

    this.hasMissingInfoError =
      this.model.organizationChangeRequest.hasMissingInformation ||
      this.model.ownerChangeRequest.hasMissingInformation ||
      this.filteredRepresentativeChangeRequests.some(it => it.hasMissingInformation);

    if (this.hasMissingInfoError) return;

    let { organization, updateProcess } = this.model;

    let confirmationTexts = {
      title: this.intl.t('organizations.profile.company-profile.modal-confirm.title'),
      subtitle: this.intl.t('organizations.profile.company-profile.modal-confirm.subtitle'),
      disclaimer: this.intl.t('organizations.profile.company-profile.modal-confirm.disclaimer'),
    };

    let result = await this.modals.open('organization-profile/confirmation-dialog', {
      updateProcess,
      confirmationTexts,
    });

    if (result !== 'confirm') return;

    try {
      // The backend will then change the status accordingly after getting `pending_review`.
      updateProcess.status = 'pending_review';

      await updateProcess.save();

      // The backend will update the KYC/KYB and the document collection status asynchronously.
      // To reflect these updates, we need to reload and re-fetch the latest status from the server.
      await updateProcess.reload();
      await this.store.query('document-collection-process', {
        filter: {
          subject_type: 'Organization',
          subject_id: organization.id,
          status: 'created,pending_upload,pending_review',
        },
        include: 'required_documents',
      });

      let message = this.intl.t(
        'organizations.profile.company-profile.toast-sent-for-review-documents'
      );
      this.toastFlashMessages.toastSuccess(message);

      this.bannerFlashMessages.clearTopBannerMessages();
      this.banners.triggerBanners();
    } catch (error) {
      let message = this.intl.t('organizations.profile.company-profile.generic-error');
      this.toastFlashMessages.toastError(message);
      this.sentry.captureException(error);
    }
  });
}
