export default {
  "body": "qfX",
  "members": "qfC",
  "members-results": "qfk",
  "members-list": "qfJ",
  "members-title": "qfs caption-bold",
  "member": "qfF",
  "empty-illustration": "qfg",
  "empty-title": "qfD title-3",
  "empty-description": "qfV body-2",
  "invitable-members": "qfL",
  "details": "qfT",
  "pagination-footer": "qfz"
};
