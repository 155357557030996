export default {
  "multi-transfer": "ZSY",
  "container": "ZSh",
  "title": "ZSf",
  "subtitle": "ZSK",
  "form": "ZSG",
  "form-title": "ZSr",
  "files-buttons": "ZSb",
  "files-buttons-item": "ZSy",
  "selected": "ZSU"
};
