export default {
  "container": "qbZ",
  "form-container": "qbR",
  "title": "qbQ",
  "title-border": "qbe",
  "tabs-container": "qbB",
  "preview-container": "qbE",
  "email-preview-container": "qbd",
  "close-button": "qbI btn btn--icon-only btn--tertiary btn--large"
};
