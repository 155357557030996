import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import window from 'ember-window-mock';

import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubHubApplicationSetupController extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service toastFlashMessages;
  @service router;
  @service modals;
  @service organizationManager;
  @service segment;
  @service webviewManager;

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';
  popup = null;

  constructor() {
    super(...arguments);
    this.handleMessage = this.handleMessage.bind(this);
  }

  get integrationName() {
    return this.model.prismicData.name;
  }

  get wizardIsPopup() {
    return this.model.solutionInstance?.wizardIsPopup;
  }

  get isGeneratingWizard() {
    return (
      (this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.PENDING ||
        this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.CREATED) &&
      (!this.model.solutionInstance?.wizardUrl || this.wizardIsPopup)
    );
  }

  get isMarkedForEnablement() {
    return this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.MARKED_FOR_ENABLEMENT;
  }

  get isEnabled() {
    return (
      !this.webviewManager.isWebview &&
      this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.ENABLED
    );
  }

  get isTransitionState() {
    return this.isGeneratingWizard || this.isMarkedForEnablement || this.hasFailed;
  }

  get hasFailed() {
    return (
      !this.webviewManager.isWebview &&
      this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.FAILED
    );
  }

  get applicationIcon() {
    return this.model.prismicData.logo.url;
  }

  get transitionCopy() {
    if (this.isGeneratingWizard) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-flow.loader-title', {
          integration_name: this.integrationName,
        }),
        subtitle: this.intl.t('qonto-hub.connect.setup-flow.loader-subtitle'),
      };
    } else if (this.isMarkedForEnablement) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-flow.activation-loader-title', {
          integration_name: this.integrationName,
        }),
        subtitle: this.intl.t('qonto-hub.connect.setup-flow.activation-loader-subtitle'),
      };
    } else if (this.hasFailed) {
      return {
        title: this.intl.t('qonto-hub.authentication.error.title'),
        subtitle: this.intl.t('qonto-hub.authentication.error.subtitle', {
          app: this.integrationName,
        }),
      };
    }
  }

  cancelSolutionTask = task(async () => {
    if (this.webviewManager.isWebview) {
      return this.webviewManager._callBridgeMethod('onConnectionCancelled');
    }
    if (this.isEnabled) {
      this.closeSetup();
    } else {
      let result = await this.modals.open('popup/destructive', {
        title: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.title'),
        description: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.description'),
        cancel: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.cancel'),
        confirm: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.stop-setup'),
      });
      if (result === 'confirm') {
        try {
          await this.model.solutionInstance.destroyRecord();
          this.closeSetup();
        } catch {
          this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
        }
      }
    }
  });

  resetSolutionTask = task(async () => {
    let solutionId = this.model.solutionInstance.solutionId;
    await this.model.solutionInstance.destroyRecord();
    let solutionInstance = await this.model.initSolutionTask.perform(solutionId);
    this.model = { ...this.model, solutionInstance };
  });

  enableSolutionTask = dropTask(async () => {
    try {
      let application = this.model.prismicData;
      await this.model.solutionInstance.enable();
      this.segment.track('connect_app_activation_flow_success_cta_clicked', {
        name: application.name,
        integrationType: application.integration_type,
        solutionId: application.tray_solution_id,
        legal_country: this.organizationManager.organization.legalCountry,
      });
      this.webviewManager._callBridgeMethod('onConnectionSuccess');
    } catch {
      if (this.webviewManager.isWebview) {
        this.webviewManager._callBridgeMethod('onConnectionFailed');
      } else {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    }
  });

  @action
  closeSetup() {
    if (this.fromTransition) {
      this.router.transitionTo(
        this.fromTransition.name,
        ...Object.values(this.fromTransition.params),
        {
          queryParams: this.fromTransition.queryParams,
        }
      );
    } else {
      this.router.transitionTo(this.detailsRouteName);
    }
  }

  @action
  openPopup() {
    this.addEventListener();
    this.popup = window.open(this.model.solutionInstance.wizardUrl, 'gmi', 'popup');
  }

  closePopup() {
    window.removeEventListener('message', this.handleMessage);
    this.popup?.close();
  }

  addEventListener() {
    window.addEventListener('message', this.handleMessage);
  }

  async handleMessage(evt) {
    if (
      evt.origin === new URL(this.model.solutionInstance.wizardUrl).origin ||
      window.location.origin
    ) {
      let { queryParamStatus, status } = evt.data;

      this.segment.track('connect_gmi_setup_completed', {
        status: queryParamStatus,
      });

      if (status === 'aborted') {
        this.closePopup();
        this.model.solutionInstance.status = SOLUTION_INSTANCE_STATUS.FAILED;
        if (this.webviewManager.isWebview) {
          this.webviewManager._callBridgeMethod('onConnectionFailed');
        }
      }
      if (status === 'success') {
        this.closePopup();
        await this.enableSolutionTask.perform().catch(ignoreCancelation);
      }
    }
  }
}
