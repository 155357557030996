export default {
  "container": "qUg",
  "wrapper": "qUD",
  "grey-header-block": "qUV",
  "connect-app-header": "qUL",
  "connect-app-header-link": "qUT",
  "connect-app-header-content": "qUz",
  "connect-app-header-content-left": "qUa",
  "application-avatar": "qUH",
  "connect-app-header-cta": "qUO",
  "connect-app-header-meta": "qUm",
  "connect-app-content": "qUp",
  "connect-app-description": "qUx",
  "title-wrapper": "qUw",
  "title": "qUN title-1",
  "tag-line": "qUv body-2",
  "connect-app-body": "qUo"
};
