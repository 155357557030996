export default {
  "mt-16": "qyI",
  "title": "qyt",
  "avatar": "qyM",
  "edit-organization-form": "qyP",
  "dropdown-content": "qyl",
  "city-block": "qyX",
  "zip-code-input": "qyC",
  "cancel-button": "qyk",
  "error-message": "qyJ",
  "nature-of-operations": "qys",
  "error": "qyF",
  "legal-code": "qyg",
  "activity-type": "qyD"
};
