import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

export default class ConfirmCompanyProfileErrorController extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service router;
  @service networkManager;
  @service deviceManager;

  get content() {
    let { isMobile } = this.deviceManager;
    let [type, confirmationToken] = this.model.message.split(':');

    if (type === 'token_expired') {
      return {
        illustrationPath: '/lotties/expired.json',
        title: this.intl.t(
          'organizations.profile.company-profile.one-click-confirmation.link-expired-screen.title'
        ),
        subtitle: this.intl.t(
          isMobile
            ? 'organizations.profile.company-profile.one-click-confirmation.link-expired-screen.mobile.subtitle'
            : 'organizations.profile.company-profile.one-click-confirmation.link-expired-screen.subtitle'
        ),
        cta: isMobile
          ? null
          : this.intl.t(
              'organizations.profile.company-profile.one-click-confirmation.link-expired-screen.cta'
            ),
        onClick: () => {
          this.router.transitionTo('organizations.show');
        },
      };
    } else {
      return {
        illustrationPath: '/lotties/error-404.json',
        title: this.intl.t(
          'organizations.profile.company-profile.one-click-confirmation.error-screen.title'
        ),
        subtitle: this.intl.t(
          'organizations.profile.company-profile.one-click-confirmation.error-screen.subtitle'
        ),
        cta: this.intl.t(
          'organizations.profile.company-profile.one-click-confirmation.error-screen.cta'
        ),
        onClick: () => {
          this.router.transitionTo('confirm-company-profile', {
            confirmation_token: confirmationToken,
          });
        },
      };
    }
  }
}
