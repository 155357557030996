export default {
  "page-wrapper": "qvn",
  "container": "qvi",
  "spinner-container": "qvA",
  "left-panel": "qvY",
  "form": "qvh",
  "left-content": "qvf",
  "form-footer": "qvK",
  "delete-button": "qvG",
  "header": "qvr",
  "preview-container": "qvb",
  "email-preview": "qvy",
  "close-button": "qvU btn btn--icon-only btn--tertiary btn--large"
};
