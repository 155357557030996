export default {
  "header": "qxd",
  "btn-preset": "qxI btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qxt",
  "statements-table": "qxM",
  "no-result": "qxP",
  "lottie-illustration": "qxl",
  "wrapper": "qxX",
  "access-denied": "qxC",
  "access-denied-illustration": "qxk",
  "application-card": "qxJ",
  "presets-wrapper": "qxs",
  "error-wrapper": "qxF",
  "error-container": "qxg",
  "error-illustration": "qxD mb-32"
};
