export default {
  "container": "qUR",
  "link-grey": "qUQ",
  "slack-section-title": "qUe title-4 mb-8",
  "edit-channel-text": "qUB body-2 mb-16",
  "rule-section-title": "qUE title-4 mb-16",
  "rule-cards-container": "qUd",
  "edit-slack-container": "qUI",
  "rule-card": "qUt",
  "add-rule-container": "qUM",
  "add-rule-link-button": "qUP",
  "add-rule-text": "qUl",
  "empty-state": "qUX",
  "empty-state-textblock": "qUC",
  "empty-state-title": "qUk title-2",
  "empty-state-description": "qUJ body-2",
  "empty-state-illustration": "qUs",
  "rules": "qUF"
};
