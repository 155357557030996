export default {
  "guests": "qiK",
  "guests--upsell-screen": "qiG",
  "no-members": "qir",
  "teams-header": "qib",
  "empty": "qiy",
  "invite-text": "qiU",
  "body": "qij",
  "members": "qiW",
  "members-list": "qiu",
  "members-title": "qAS caption-bold",
  "member": "qAc",
  "details": "qAq"
};
