export default {
  "header": "qyV",
  "content": "qyL",
  "read-only": "qyT",
  "document-collection-banner": "qyz",
  "submit-banner": "qya",
  "review-banner": "qyH",
  "disclaimer": "qyO",
  "title": "qym",
  "card-container": "qyp",
  "representatives-section": "qyx",
  "disabled-href": "qyw"
};
