export default {
  "container": "qGv",
  "form-container": "qGo",
  "title": "qGn",
  "title-border": "qGi",
  "tabs-container": "qGA",
  "preview-container": "qGY",
  "email-preview-container": "qGh",
  "close-button": "qGf btn btn--icon-only btn--tertiary btn--large"
};
