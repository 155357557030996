import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';
import {
  getEmptyStateConfig,
  TAB_NAMES,
} from 'qonto/constants/empty-states/direct-debit-collections';
import { TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';
import { getStatusLabel } from 'qonto/models/direct-debit-collection';
import { EligibilityLossBanner } from 'qonto/react/components/direct-debit-collections/eligibility-loss-banner';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;
const DEFAULT_SORT_BY = 'executed_at:desc';

export default class DirectDebitCollectionsCompletedController extends Controller {
  @service notifierCounterManager;
  @service organizationManager;
  @service segment;
  @service emptyStates;
  @service intl;
  @service directDebitCollectionsManager;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  queryParams = [
    'highlight',
    'page',
    'perPage',
    'sortBy',
    'statusFilter',
    'search',
    { bankAccounts: 'bank-accounts' },
  ];

  @tracked highlight = '';
  @tracked page = DEFAULT_PAGE;
  @tracked perPage = DEFAULT_PER_PAGE;
  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked bankAccounts = null;
  @tracked statusFilter = null;
  @tracked search = null;

  EligibilityLossBanner = EligibilityLossBanner;

  get collections() {
    return this.model.fetchCollectionsTask.lastSuccessful?.value ?? [];
  }

  get meta() {
    return this.collections.meta;
  }

  get availableStatuses() {
    return Object.values(DIRECT_DEBIT_COLLECTION_STATUS);
  }

  get isEmptyState() {
    return this.collections.length === 0 && !this.model.fetchCollectionsTask.isRunning;
  }

  get hasPendingRequests() {
    return this.notifierCounterManager.counter?.directDebitCollectionRequests > 0;
  }

  get isOrganizationFrenchOrGerman() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' ||
      this.organizationManager.organization.legalCountry === 'DE'
    );
  }

  get emptyStateRevampOptions() {
    let { isEligible } = this.model;

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: isEligible,
      isEmptyGlobally: false,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: Boolean(this.statusFilter || this.search),
      config: getEmptyStateConfig(this.intl, {}),
      tab: TAB_NAMES.COMPLETED,
    });
  }

  @action
  getStatusLabel(status) {
    return getStatusLabel(status, this.intl);
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = DEFAULT_PAGE;
    this.perPage = DEFAULT_PER_PAGE;
    this.sortBy = DEFAULT_SORT_BY;
    this.bankAccounts = null;
  }

  // ItemSelector provides id on @didHighlight
  // so, we should work with id here
  @action
  handleSelectCollection(collectionId) {
    if (!collectionId) {
      this.highlight = null;
      return;
    }

    this.highlight = collectionId;

    this.segment.track('incoming-direct-debits_side-panel_opened');
  }

  @action
  handleSortBy(sortBy) {
    this.page = DEFAULT_PAGE;
    this.sortBy = sortBy;
  }

  @action
  handlePerPageChange(perPage) {
    this.page = DEFAULT_PAGE;
    this.perPage = perPage;
  }

  @action
  setupDirectDebitSubscription() {
    if (this.isEmptyState && this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, TRACKING_ORIGINS.HEADER);
    }

    this.directDebitCollectionsManager.handleRedirectionOnSetupCollection({
      redirectRoute: 'direct-debit-collections.completed',
    });
  }
}
