export default {
  "container": "qUY",
  "content": "qUh",
  "setup-box": "qUf",
  "setup-wizard": "qUK",
  "setup-success": "qUG",
  "setup-success-lottie": "qUr",
  "setup-close-btn": "qUb",
  "tray-connection": "qUy",
  "close-button": "qUU"
};
