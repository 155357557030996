import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class EditSupplierRoute extends Route {
  @service modals;
  @service intl;
  @service abilities;
  @service homePage;
  @service store;
  @service organizationManager;
  @service toastFlashMessages;
  @service router;
  @service sentry;

  supplier;
  modal;
  referrerSupplierInvoiceId;

  beforeModel() {
    if (this.abilities.cannot('update supplier')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model({ id }, { from }) {
    if (from?.name === 'supplier-invoices.show') {
      this.referrerSupplierInvoiceId = from.params?.invoice_id;
    }
    this.supplier = await this.store.findRecord('supplier', id);
    this.modal = this.modals.open(
      'suppliers/supplier-modal',
      {
        title: this.intl.t('supplier-invoices.supplier-edition.title'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.save-changes'),
        confirmTask: this.updateSupplierTask,
        supplier: this.supplier,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
        },
      }
    );

    return { supplier: this.supplier };
  }

  updateSupplierTask = dropTask(async () => {
    try {
      await this.supplier.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t(
          'supplier-invoices.supplier-invoice-details.updated-supplier.confirmation-toast'
        )
      );
      if (this.referrerSupplierInvoiceId) {
        await this.updateSupplierInvoice();
        this.router.transitionTo('supplier-invoices.show', this.referrerSupplierInvoiceId);
      } else {
        this.router.transitionTo('suppliers.index');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error.errors.some(errorObj => errorObj?.status === '409')) {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.supplier-invoice-details.form.duplicate-supplier-toast')
        );
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
    }
  });

  async updateSupplierInvoice() {
    let invoice = await this.store.peekRecord('supplier-invoice', this.referrerSupplierInvoiceId);
    invoice.supplierName = this.supplier.name;
    invoice.supplierSnapshot = {
      id: this.supplier.id,
      name: this.supplier.name,
    };
    invoice.save();
  }

  deactivate() {
    this.modal.close();
  }
}
