export default {
  "title": "qjr title-3",
  "personal-profile-container": "qjb",
  "personal-profile-section": "qjy",
  "member-info-title": "qjU",
  "kyc-complete-profile": "qjj",
  "info-fields": "qjW",
  "field": "qju",
  "address-fields": "qWS",
  "field-span": "qWc",
  "input": "qWq",
  "cancel": "qWZ"
};
