export default {
  "accounts": "qxb",
  "header-subtitle": "qxy title-4",
  "subtitle-container": "qxU",
  "subtitle": "qxj title-3 mb-16",
  "amount": "qxW body-2",
  "list--cashbeeSavings": "qxu",
  "connections-banner": "qwS",
  "list": "qwc",
  "icon": "qwq",
  "badge": "qwZ",
  "discover-tile": "qwR"
};
