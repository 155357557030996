export default {
  "container": "qWN",
  "header-cell": "qWv caption-bold",
  "col-8": "qWo",
  "table": "qWn",
  "cell": "qWi body-2",
  "cell-content": "qWA",
  "row": "qWY",
  "empty": "qWh",
  "quick-actions": "qWf",
  "card-infos": "qWK",
  "card-digits": "qWG",
  "empty-state": "qWr"
};
