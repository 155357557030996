export default {
  "page-container": "qYK",
  "form": "qYG",
  "close-button": "qYr btn btn--icon-only btn--tertiary btn--large",
  "preview": "qYb",
  "preview-padding": "qYy",
  "title": "qYU",
  "header": "qYj",
  "without-tabs": "qYW",
  "form-section": "qYu",
  "form-footer": "qhS",
  "disclaimer-text": "qhc"
};
