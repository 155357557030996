export default {
  "page-wrapper": "qxV",
  "container": "qxL",
  "sidebar": "qxT",
  "search-input": "qxz",
  "organizations-list": "qxa",
  "content": "qxH",
  "no-result": "qxO",
  "lottie-illustration": "qxm",
  "accounting-header": "qxp",
  "logo": "qxx",
  "accounting-title": "qxw"
};
