export default {
  "bank-accounts": "qbG l-app-content__wrapper",
  "header-wrapper": "qbr",
  "scrolled-top": "qbb",
  "header": "qby l-app-content__page-header",
  "create-account-button": "qbU btn btn--primary",
  "main": "qbj",
  "accounts": "qbW",
  "subtitle-container": "qbu",
  "subtitle": "qyS title-3 mb-16",
  "amount": "qyc body-2",
  "list--cashbeeSavings": "qyq"
};
