export default {
  "container": "qGG",
  "form-container": "qGr",
  "preview-container": "qGb",
  "credit-note-preview-container": "qGy",
  "credit-note-preview": "qGU",
  "content": "qGj",
  "title": "qGW",
  "tabs-container": "qGu",
  "close-button": "qrS btn btn--icon-only btn--tertiary btn--large",
  "header": "qrc",
  "without-tabs": "qrq"
};
