export default {
  "details": "qhO",
  "header": "qhm",
  "avatar": "qhp mr-16",
  "header-beneficiary": "qhx",
  "header-beneficiary-activity": "qhw",
  "header-beneficiary-details": "qhN",
  "header-beneficiary-details-recurring": "qhv",
  "infos": "qho",
  "infos-title": "qhn",
  "infos-empty": "qhi",
  "infos-list": "qhA",
  "infos-list-item": "qhY",
  "infos-list-item-left": "qhh",
  "infos-list-item-right": "qhf",
  "infos-list-item-attachment": "qhK",
  "infos-transactions": "qhG",
  "actions": "qhr"
};
