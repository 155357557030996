import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

import { DEFAULT_SORT_ORDER, NRC_PAYMENTS_SORT_BYS } from 'qonto/constants/sort';

export default class AgenciaTributariaListCompletedController extends Controller {
  @service store;
  @service intl;
  @service zendeskLocalization;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${NRC_PAYMENTS_SORT_BYS.EXECUTION_DATE}:${DEFAULT_SORT_ORDER}`;

  queryParams = ['highlight', 'page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  @action onCtaClick() {
    let link = this.intl.t('aeat.empty-state.secondary-cta-url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle(9023812),
    });
    window.open(link, '_blank');
  }

  @action updateParam(param, value) {
    this[param] = value;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
  }

  get highlightableItems() {
    let { highlight, model } = this;
    if (highlight) {
      let nrcPayment = this.store.peekRecord('nrc-payment', highlight);
      if (nrcPayment && !model.includes(nrcPayment)) {
        return [...model, nrcPayment];
      }
    }
    return model;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
