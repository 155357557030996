export default {
  "container": "qrg",
  "form-container": "qrD",
  "preview-container": "qrV",
  "pdf-preview-container": "qrL",
  "content": "qrT",
  "title": "qrz",
  "tabs-container": "qra",
  "close-button": "qrH btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrO",
  "header": "qrm",
  "without-tabs": "qrp"
};
