import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class BaseReceivableInvoicesRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service store;

  fetchFrEinvoicingSettingsTask = dropTask(async () => {
    let canRead = this.abilities.can('read einvoicingSetting');

    if (!(this.organizationManager.organization.legalCountry === 'FR' && canRead)) {
      return false;
    }
    try {
      let frEinvoicingSettings = await this.store.findRecord(
        'einvoicing-settings',
        this.organizationManager.organization.id
      );
      return frEinvoicingSettings?.einvoicingOnboarded;
    } catch {
      return false;
    }
  });
}
