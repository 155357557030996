export default {
  "page-wrapper": "qvR",
  "back-button": "qvQ",
  "abort-button": "qve",
  "container": "qvB",
  "subtitle": "qvE",
  "button": "qvd",
  "panel-list": "qvI",
  "panel-list-item": "qvt",
  "card-illustration": "qvM"
};
