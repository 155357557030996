import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/supplier-invoices';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import {
  BASE_INSIGHTS,
  DUE_DATE_FILTER,
  DUE_FILTER_EVENT_MAP,
  INSIGHT_LABELS,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { deserializeArrayQP, serializeArrayQP } from 'qonto/utils/query-params';

export const IN_CONTEXT_INTEGRATIONS_VIEWED_KEY = 'supplier-invoices-ici-viewed';

export default class SupplierInvoicesController extends Controller {
  @service intl;
  @service router;
  @service organizationManager;
  @service abilities;
  @service supplierInvoicesUploadManager;
  @service supplierInvoicesInsights;
  @service segment;
  @service emptyStates;
  @service flowLinkManager;
  @service subscriptionManager;
  @service modals;
  @service sentry;
  @service toastFlashMessages;
  @service networkManager;

  queryParams = [
    'page',
    'perPage',
    'status',
    'sortBy',
    'supplierInvoiceId',
    'transferStatus',
    'items',
    'dueDate',
    'supplierId',
  ];

  @tracked suppliersOptions;
  @tracked isCockpitFolded = false;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked status = INVOICE_STATUSES.toReview;
  @tracked sortBy = 'due_date:asc';
  @tracked showDropzone = false;
  @tracked isSideDrawerDisplayed = false;
  @tracked items = '';
  @tracked dueDate;
  @tracked supplierId = undefined;

  get selectedItems() {
    return deserializeArrayQP(this.items).filter(selectedItemId =>
      this.supplierInvoices.some(({ id }) => id === selectedItemId)
    );
  }

  get showSupplierFilter() {
    return (
      variation('feature--boolean-supplier-hub') &&
      this.abilities.can('view supplier') &&
      this.suppliers.length > 0
    );
  }

  get selectedSupplierName() {
    return this.suppliers?.find(({ id }) => id === this.supplierId);
  }

  get suppliers() {
    return this.suppliersOptions || this.model.fetchSuppliersTask.lastSuccessful?.value || [];
  }

  get showBulkActions() {
    return this.numberOfSelected > 0 && this.isImportedTab;
  }

  get numberOfSelected() {
    return this.selectedItems.length;
  }

  get isImportedTab() {
    return this.status === INVOICE_STATUSES.toReview;
  }

  get isCompletedTab() {
    return (
      this.status.includes(INVOICE_STATUSES.archived) || this.status.includes(INVOICE_STATUSES.paid)
    );
  }

  get isFiltered() {
    if (this.isCompletedTab) return this.status?.split(',').length === 1;
    return Boolean(this.dueDate);
  }

  get showFilterRow() {
    return (
      (this.isImportedTab || this.isCompletedTab) &&
      (this.supplierInvoices.length > 0 || this.isFiltered)
    );
  }

  get filterOptions() {
    if (this.isImportedTab)
      return [
        {
          code: 'past_and_today',
          value: this.intl.t('supplier-invoices.imported.filter.option.due'),
        },
        {
          code: 'future',
          value: this.intl.t('supplier-invoices.imported.filter.option.upcoming'),
        },
        {
          code: 'missing_date',
          value: this.intl.t('supplier-invoices.imported.filter.option.missing-due-date'),
        },
      ];
    else {
      return [
        {
          code: INVOICE_STATUSES.paid,
          value: this.intl.t('supplier-invoices.preview.status.paid'),
        },
        {
          code: INVOICE_STATUSES.archived,
          value: this.intl.t('supplier-invoices.preview.status.archived'),
        },
      ];
    }
  }

  get filterPlaceholder() {
    if (this.isImportedTab) {
      return this.intl.t('supplier-invoices.imported.filter.label');
    }

    return this.intl.t('supplier-invoices.completed.filter.label');
  }

  get filterValue() {
    if (this.isImportedTab) {
      return this.filterOptions.find(el => el.code === this.dueDate);
    }

    return this.filterOptions.find(el => el.code === this.status);
  }

  get emptyStateRevampOptions() {
    if (!variation('feature--boolean-empty-state-revamp')) {
      return;
    }
    if (this.isLoading) {
      return;
    }

    let tab = this.status;
    if (tab === 'pending' && !this.abilities.can('review transfer request')) {
      tab = 'requests';
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: Boolean(this.filterValue),
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        tab,
      },
      abilities: {
        canUploadSupplierInvoices: this.abilities.can('upload supplierInvoice'),
        canUpdateSupplierInvoices: this.abilities.can('update supplierInvoice'),
      },
    });
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('supplierInvoices');
  }

  get isEmptyGlobally() {
    return ![
      INSIGHT_LABELS.pendingCounter,
      INSIGHT_LABELS.inboxCounter,
      INSIGHT_LABELS.scheduledCounter,
      INSIGHT_LABELS.paidCounter,
      ...this.insightLabels,
    ].some(key => this.currentInsights[key] > 0);
  }

  get isEmptyLocally() {
    let { status, currentInsights, dueDate } = this;

    let displayDueDateFilterNoResults = {
      [DUE_DATE_FILTER.PAST_AND_TODAY]: !currentInsights[INSIGHT_LABELS.totalDueCounter],
      [DUE_DATE_FILTER.FUTURE]: !this.supplierInvoices.length,
      [DUE_DATE_FILTER.MISSING_DATE]: !this.supplierInvoices.length,
    };

    if (status === INVOICE_STATUSES.pending) {
      return !currentInsights[INSIGHT_LABELS.pendingCounter];
    } else if (status === INVOICE_STATUSES.toReview) {
      return dueDate
        ? displayDueDateFilterNoResults[dueDate]
        : !currentInsights[INSIGHT_LABELS.inboxCounter];
    } else if (status === INVOICE_STATUSES.scheduled) {
      return !currentInsights[INSIGHT_LABELS.scheduledCounter];
    } else if (status === INVOICE_STATUSES.paid) {
      // remove !this.supplierInvoices.length paidCounter starts returning correct count
      return !currentInsights[INSIGHT_LABELS.paidCounter] && !this.supplierInvoices.length;
    } else if (status === INVOICE_STATUSES.archived) {
      return !this.supplierInvoices.length;
    } else if (status === `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`) {
      return !this.supplierInvoices.length;
    }
    return false;
  }

  get isNotInformEmptyState() {
    return this.emptyStateRevampOptions?.layout !== LAYOUT.INFORM;
  }

  get isOrganisationItalian() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  inContextIntegrationsViewed =
    safeLocalStorage.getItem(IN_CONTEXT_INTEGRATIONS_VIEWED_KEY) === 'true';

  get shouldAnimateInContextIntegrations() {
    return (
      variation('operational--supplier-invoices-animate-ici-trigger') &&
      !this.inContextIntegrationsViewed
    );
  }

  get isLoading() {
    return (
      this.model.supplierInvoicesTask.isRunning || this.supplierInvoicesInsights.isFetchingInsights
    );
  }

  get localState() {
    let isShowRoute = this.router.currentRouteName.includes('supplier-invoices.show');

    let localState = {
      isEmpty: false,
      isError: false,
      isLoading: false,
    };

    if (this.isLoading && !isShowRoute) {
      localState.isLoading = true;
    } else if (this.model.supplierInvoicesTask.last?.isError) {
      localState.isError = true;
    } else if (
      this.supplierInvoices.length === 0 &&
      !variation('feature--boolean-empty-state-revamp')
    ) {
      localState.isEmpty = true;
    }

    return localState;
  }

  get supplierInvoices() {
    return this.model.supplierInvoicesTask.lastSuccessful?.value || [];
  }

  get insightLabels() {
    return BASE_INSIGHTS;
  }

  get currentInsights() {
    return this.supplierInvoicesInsights.currentInsights;
  }

  get previousInsights() {
    return this.supplierInvoicesInsights.previousInsights;
  }

  get isExportButtonEnabled() {
    return this.model.supplierInvoicesTask.isRunning || !this.isEmptyGlobally;
  }

  get importingPopoverCopies() {
    return {
      'in-progress': 'supplier-invoices.importing-modal.title.in-progress',
      errors: 'supplier-invoices.importing-modal.title.errors',
      complete: 'supplier-invoices.importing-modal.title.complete',
    };
  }

  searchSuppliers(query, resolve, reject) {
    return this.model.fetchSuppliersTask
      .perform(query)
      .catch(error => reject(error))
      .then(res => resolve(res));
  }

  @action handleSupplierSearch(query) {
    if (!this.suppliersOptions) {
      this.suppliersOptions = this.suppliers;
    }

    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }

  @action selectSupplier(supplier) {
    this.supplierId = supplier?.id;
  }

  @action
  showBulkDeletePopup() {
    let numberOfSelectedWithNonFinancialAttachment = this.supplierInvoices.reduce(
      (count, invoice) =>
        this.selectedItems.includes(invoice.id) && invoice.isAttachmentNonFinancial
          ? count + 1
          : count,
      0
    );

    this.segment.track('supplier-invoices_delete_clicked', {
      action_type: 'bulk',
      selected_invoices_count: this.numberOfSelected,
      non_financial_documents_count: numberOfSelectedWithNonFinancialAttachment,
    });

    this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.delete.title', {
        count: this.numberOfSelected,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.delete.subtitle', {
        count: this.numberOfSelected,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.delete.confirm', {
        count: this.numberOfSelected,
      }),
      confirmTask: this.bulkDeleteTask,
    });
  }

  @action selectItems(newArr) {
    if (newArr.length > 0) {
      this.segment.track('supplier-invoices_multiselection_clicked', { selected: newArr.length });
    }
    this.items = serializeArrayQP(newArr);
  }

  @action
  showBulkArchivePopup() {
    this.segment.track('supplier-invoices_archive_clicked', { action_type: 'bulk' });

    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.archive.title', {
        count: this.numberOfSelected,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.archive.subtitle', {
        count: this.numberOfSelected,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.archive.confirm', {
        count: this.numberOfSelected,
      }),
      confirmTask: this.bulkArchiveTask,
    });
  }

  @action
  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'supplier-invoices',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleClickTracking(origin) {
    if (variation('feature--boolean-empty-state-revamp')) {
      this.trackCtaEvent(origin);
    } else {
      this.segment.track('supplier-invoices_upload-button_clicked');
    }
  }

  @action
  onInvoiceListScroll(isScrolledTop) {
    // The condition is due to a known scroll bug, the cockpit is not folded under 16 invoices in the table.
    if (this.supplierInvoices.length > 15) {
      this.isCockpitFolded = !isScrolledTop;
    }
  }

  @action
  changePage(page) {
    this.page = page;
  }

  @action
  changePerPage(perPage) {
    this.page = 1;
    this.perPage = perPage;
  }

  @action changeSortBy(sortBy) {
    this.sortBy = sortBy;
  }

  @action
  toggleShowDropzone(boolean) {
    this.showDropzone = boolean;
  }

  @action
  reload() {
    this.supplierInvoicesInsights.fetchInsights();
  }

  @action
  onSideDrawerDisplay() {
    this.inContextIntegrationsViewed = true;
    this.isSideDrawerDisplayed = true;
  }

  @action
  onSideDrawerHide() {
    this.isSideDrawerDisplayed = false;
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      if (this.isImportedTab) this.dueDate = null;
      if (this.isCompletedTab)
        this.status = [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived].join(',');
      return;
    }
    if (this.isImportedTab) {
      this.segment.track('supplier-invoices_filter_select', {
        filter_type: 'due_date',
        filter_value: DUE_FILTER_EVENT_MAP[selected.code],
      });
      this.dueDate = selected?.code;
    } else {
      this.segment.track('supplier-invoices_filter_select', {
        filter_type: 'status',
        filter_value: selected.code,
      });
      this.status = selected?.code ?? `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`;
    }
  }

  @action
  onUploadPopoverClose() {
    this.supplierInvoicesUploadManager.resetState();
    this.segment.track('supplier-invoices_upload-component_close-button_clicked');
  }

  @action
  onPreviewFile(file) {
    this.segment.track('supplier-invoices_upload-component_invoice-item_clicked');
    this.router.transitionTo('supplier-invoices.show', file.invoiceId);
  }

  bulkArchiveTask = dropTask(async close => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.selectedItems,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.success', {
          count: this.numberOfSelected,
        })
      );
      this.model.refreshModel();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.items = '';
      close();
    }
  });

  bulkDeleteTask = dropTask(async close => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_delete`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.selectedItems,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.delete', {
          count: this.numberOfSelected,
        })
      );
      this.model.refreshModel();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.items = '';
      close();
    }
  });
}
