export default {
  "mandates": "qhb",
  "mandates-empty": "qhy",
  "header": "qhU",
  "header-empty": "qhj",
  "header-content": "qhW",
  "search": "qhu",
  "search-bar": "qfS",
  "search-spinner": "qfc",
  "body": "qfq",
  "isEmpty": "qfZ",
  "left-section": "qfR",
  "mandates-list": "qfQ",
  "mandate-suspended": "qfe",
  "list-title": "qfB caption-bold",
  "list-empty": "qfE",
  "empty-illustration": "qfd",
  "empty-title": "qfI title-3",
  "empty-description": "qft body-2",
  "body-details": "qfM",
  "l-app-content__page-header": "qfP",
  "pagination-footer": "qfl"
};
