export default {
  "container": "qAA",
  "compact-header": "qAY",
  "visible": "qAh",
  "page-container": "qAf",
  "main": "qAK",
  "tabs": "qAG",
  "tabs-nav": "qAr",
  "tabs-panels": "qAb",
  "tabs-panel": "qAy body-2",
  "sidebar": "qAU",
  "prismic-content": "qAj"
};
