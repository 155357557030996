import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class InsuranceHubCategoriesRoute extends Route {
  @service insuranceManager;
  @service router;

  beforeModel() {
    if (!variation('feature--boolean-insurance-hub-v1')) {
      this.router.replaceWith('insurance-hub');
    }
  }

  async model(params) {
    let { category_slug: categorySlug } = params;

    return await this.insuranceManager.getInsuranceOffers(categorySlug);
  }
}
