import { guidFor } from '@ember/object/internals';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

export default class MembersRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service homePage;
  @service organizationManager;
  @service router;
  @service store;
  @service subscriptionManager;

  queryParams = {
    teamId: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
  };
  rootRouteName = `members`;

  elementId = guidFor(this);
  inputId = `${this.elementId}-search-input`;

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    if (
      transition.from?.name === 'protected-deeplinks' &&
      transition.from?.queryParams.tab === 'invite'
    ) {
      this.router.transitionTo('members.to-invite');
    } else if (
      transition.to.name === 'members' ||
      transition.to.name === 'members.index' ||
      transition.to.name === 'members.list.member'
    ) {
      this.router.transitionTo('members.active');
    }

    let userLimit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
    let shouldUpgrade = userLimit === 1 && this.abilities.cannot('add admin only members');

    if (!variation('feature--boolean-modular-pricing-admins-for-solo')) {
      shouldUpgrade = userLimit === 1;
    }

    if (shouldUpgrade) {
      return this.router.replaceWith(`${this.rootRouteName}.upgrade-plan`);
    }

    if (this.abilities.cannot('access members')) {
      return this.homePage.visitDefaultPage();
    }
  }

  paginatedMembersResponseTask = restartableTask(
    async ({ page, perPage, teamId, paginatedFilters }, { suggested = false } = {}) => {
      let { query } = this.paramsFor('members');
      let schema = 'membership';

      if (suggested) {
        schema = 'suggested-invitation';
      } else {
        paginatedFilters.team_ids = [];

        if (isPresent(teamId)) {
          paginatedFilters.team_ids = [teamId];
        }
      }

      let payload = {
        organization_id: this.organizationManager.organization.id,
        per_page: perPage,
        page: page ?? 1,
        filters: paginatedFilters,
      };

      if (query) {
        if (suggested) {
          payload.q = query;
        } else {
          payload.query = query;
        }
      }

      let paginatedMembersResponse = await this.store.query(schema, payload);

      this.handleSearchInputFocus();
      return paginatedMembersResponse;
    }
  );

  handleSearchInputFocus() {
    let queryString = `#${this.inputId} input`;
    document.querySelector(queryString)?.focus();
  }

  model() {
    return {
      inputId: this.inputId,
      paginatedMembersResponseTask: this.paginatedMembersResponseTask,
    };
  }

  redirect(model, transition) {
    if (transition.to.name === this.rootRouteName + '.index') {
      this.router.transitionTo(`${this.rootRouteName}.list`);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ page: 1, query: null, teamId: null });
    }
  }
}
