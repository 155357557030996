export default {
  "supplier-invoices-page-header": "quN",
  "filters-container": "quv",
  "filters": "quo",
  "bulk-actions": "qun",
  "item-count": "qui",
  "higher-index": "quA",
  "title-wrapper": "quY",
  "title": "quh",
  "subtitle": "quf",
  "table-container": "quK",
  "file-dropzone": "quG",
  "dropzone-visible": "qur",
  "header-actions": "qub",
  "header-wrapper": "quy"
};
