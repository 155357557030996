export default {
  "member-details": "qiP",
  "header": "qil",
  "header-img": "qiX",
  "invited-persona": "qiC",
  "revoked-persona": "qik",
  "invited-icon": "qiJ",
  "revoked-icon": "qis",
  "dropdown": "qiF",
  "header-body": "qig",
  "name": "qiD title-3",
  "email": "qiV caption-bold",
  "actions": "qiL body-2",
  "actions-cards": "qiT",
  "actions-transactions": "qiz",
  "body": "qia",
  "expense-permissions-section": "qiH",
  "body-title": "qiO title-4",
  "body-attr": "qim",
  "body-label": "qip",
  "attr-buttons": "qix",
  "body-role": "qiw small",
  "details-actions": "qiN",
  "actions-item": "qiv",
  "disclaimer": "qio",
  "permissions": "qin",
  "permission": "qii",
  "permission-label": "qiA",
  "permission-check": "qiY",
  "permission-missing": "qih",
  "editable-section": "qif"
};
