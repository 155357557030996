export default {
  "wrapper": "qhq",
  "header": "qhZ",
  "main": "qhR",
  "frequency": "qhQ body-1",
  "radiogroup": "qhe",
  "divider": "qhB",
  "option-wrapper": "qhE",
  "option-details-wrapper": "qhd",
  "option-label": "qhI",
  "option-name": "qht",
  "option-details-text": "qhM",
  "spinner-wrapper": "qhP",
  "spinner": "qhl"
};
