export default {
  "page-header": "Zct",
  "header-breadcrumb": "ZcM",
  "tabs-container": "ZcP",
  "bottom-border": "Zcl",
  "tabs-separator": "ZcX",
  "previous-request": "ZcC",
  "body-link": "Zck",
  "border-top": "ZcJ"
};
